export const UserRole = {
  Admin: 0,
  Editor: 1,
};

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563',
};

export const adminRoot = '/admin';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/#`;
export const servicePath = 'https://api.coloredstrategies.com';

export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:24',
  role: UserRole.Admin,
};

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = false;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];

//admin endpoints
export const ADMIN_LOGIN = "/login/send_login_code";
export const GET_ADMIN_USER = "/users/";
export const CHANGE_ADMIN_PASSWORD = "/users/update_password/";
export const UPDATE_ADMIN_USER = "/users/";
export const VERIFY_LOGIN_CODE = "/login/verify_login_code";
export const DASHBOARD = "/dashboard";
export const EMAIL_REPORTS = "/email_report";
export const FAILED_PAYMENTS = "/failed_payments";
export const GET_CLIENT = "/clients/";
export const DELETE_CLIENT = "/clients/";
export const AUDIT_REPORTS = "/audit_report";
export const GET_USERS = "/users";
export const ADD_USER = "/users/";
export const UPDATE_USER = "/users/";
export const DELETE_USER = "/users/";
export const GET_USER = "/users/";
export const GET_FAILED_PAYMENTS_EMAIL = "/failed_payments_email_log/";
export const GET_CUSTOM_EMAILS = "/custom_email_alerts";
export const CREATE_CUSTOM_EMAIL = "/custom_email_alerts/";
export const GET_CUSTOM_EMAIL  = "/custom_email_alerts/";
export const UPDATE_CUSTOM_EMAIL = "/custom_email_alerts/";
export const DELETE_CUSTOM_EMAIL = "/custom_email_alerts/";
export const SEND_TEST_MAIL = "/custom_email_alerts_send_test_mail";
export const SEARCH_CONTACTS = "/custom_email_alerts/contacts";
export const ASSIGN_CONTACTS = "/custom_email_alerts/contacts_assign/";
export const GET_ASSIGNED_CONTACTS = "/custom_email_alerts/contacts_assign/";
export const DELETE_CONTACTS = "/custom_email_alerts/contacts_assign/";
export const REMOVE_ALL_CONTACTS = "/custom_email_alerts/contacts_remove_all/";
export const SEARCH_CLIENTS = "/clients/search";
export const RESET_CLIENT_PASSWORD = "/clients/reset_password/";
export const GET_EMAIL_SMS_ALERT_NUMBER = "/clients/alert_email_sms/";
export const UPDATE_EMAIL_SMS_ALERT_NUMBER = "/clients/alert_email_sms/";
export const GET_PAYMENT_METHODS = "/clients/payment_methods/";
export const GET_COUNTRIES = "/countries";
export const GET_INVOICES = "/invoices/";
export const GET_INVOICES_DETAILS = "/invoices/";
export const GET_SUBSCRIPTION = "/clients/subscriptions/";
export const DELETE_PAYMENT_METHOD = "/clients/payment_methods_delete/";
export const ADD_CARD = "/clients/payment_methods_add/";
export const MAKE_CARD_DEFAULT = "/clients/payment_methods/";
export const DELETE_CARD = "/clients/payment_methods_delete/";
export const UPDATE_CLIENT = "/clients/";
export const STOP_ALERTS = "/clients/stop_alerts/";
export const UNSUBSCRIBE_CLIENT = "/clients/subscriptions/unsubscribe/";
export const CREATE_REPORTS = "/reports/compare_alerts_to_active_clients";
export const GET_CUSTOM_EMAIL_LIST = "/custom_email_report/custom_email_list";
export const GET_CUSTOM_EMAIL_REPORT = "/custom_email_report/";
export const REFUND_INVOICE = "/invoices/refund/";
export const GET_ALERTS_DATA = "/control_panel/";
export const UPDATE_ALERTS_DATA = "/control_panel/";
export const GET_ADDRESS_AND_TIMEZONE = "/control_panel/timezone_address";
export const UPDATE_ALERT_LOCATION = "/control_panel/update_alert_location/";
// export const DELETE_CLIENT = "/clients/";